import React from "react";
import {
  BannerPage,
  ButtonPrimary,
  ButtonSecondary,
  Colors,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  ExternalLink,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
  TitleFloorPlan,
} from "../components";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col } from "antd";
import Img from "gatsby-image";

const Displays = () => {
  const data = useStaticQuery(graphql`
    query {
      allDisplaysJson {
        nodes {
          name
          address
          addressLink
          estate
          estateLink
          slug
          description
          floorPlan {
            size
            storey
            bed
            bath
            living
            carSpace
            site
            totalSquares
          }
          facade {
            imagePath {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const displays = data.allDisplaysJson.nodes;

  return (
    <Layout>
      <SEO
        title="Display Homes"
        description={"Display Homes in leading estates in Victoria"}
      />
      <BannerPage
        title={"Display Homes"}
        subTitle={"Display Homes in leading estates in Victoria"}
      />

      <ContainerContent>
        <ContainerContentFixed>
          <div>
            {displays.map(d => (
              <Row
                key={d.slug}
                gutter={[8, 8]}
                style={{ marginBottom: "16px" }}
              >
                <Col lg={14} sm={24} xs={24}>
                  {d.facade && (
                    <Img fluid={d.facade.imagePath.childImageSharp.fluid} />
                  )}
                </Col>
                <Col lg={10} sm={24} xs={24}>
                  <div style={{ padding: "2rem 1rem" }}>
                    <TitleFloorPlan
                      style={{ fontSize: "1.5rem" }}
                      size={d.floorPlan ? d.floorPlan.size : null}
                      name={d.name}
                    />{" "}
                    <h4
                      style={{
                        fontWeight: 600,
                        marginTop: "16px",
                        marginBottom: "8px",
                      }}
                    >
                      {d.estate}
                      {/*<ExternalLink url={d.estateLink}>{d.estate}</ExternalLink>*/}
                    </h4>{" "}
                    {d.address && (
                      <h4
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        {d.address}
                        <br />
                        {d.addressLink && (
                          <ExternalLink url={d.addressLink}>
                            <small>Open in Google Maps</small>
                          </ExternalLink>
                        )}
                      </h4>
                    )}
                    <p style={{ fontSize: "1rem" }}>{d.description}</p>
                    {d.slug && (
                      <>
                        <ButtonPrimary style={{ margin: "4px" }}>
                          <InternalLink url={d.slug}>Browse</InternalLink>
                        </ButtonPrimary>
                        <ButtonSecondary style={{ margin: "4px" }}>
                          <InternalLink
                            url={InternalLinkTypes.InspectionByDisplay({
                              display: d.name,
                            })}
                          >
                            Request Inspection
                          </InternalLink>
                        </ButtonSecondary>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerContent style={{ backgroundColor: Colors.LightGray }}>
        <ContainerContentFixed style={{ textAlign: "center" }}>
          <section style={{ textAlign: "center" }}>
            <InternalLink url={InternalLinkTypes.Disclaimer}>
              See our disclaimer
            </InternalLink>
          </section>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerCta
        title={"Need more information?"}
        secondaryLink={InternalLinkTypes.Contact}
        secondaryText={"Contact Our Team"}
      />
    </Layout>
  );
};

export default Displays;
